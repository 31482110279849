import * as React from "react";
import {hover} from "@testing-library/user-event/dist/hover";
import {NavLink} from 'react-router-dom';
import countries from 'i18n-iso-countries';
import {CountryContext, Data} from "react-svg-worldmap";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import WorldMap from "react-svg-worldmap";
import {useEffect, useState} from 'react';
import Marquee from "react-fast-marquee";
import image from "../assets/svg/infinit.svg";
import axios from "axios";

let test = 0;

const Country = (props) => {

    countries.registerLocale(require("i18n-iso-countries/langs/ar.json"));
    const [getOpenSelect, setOpenSelect] = useState(false);
    const [getCountryMap, setCountryMap] = useState(false);
    const [getCountryMap2, setCountryMap2] = useState(false);
    const [getInstitutes, setInstitutes] = useState(0);
    const [getCountryActive, setCountryActive] = useState('CA');
    const [getLoadercounter, setLoadercounter] = useState(false);
    const [getCountryFilter, setCountryFilter] = useState(false);
    const [getcountry1, setcountry1] = useState([]);
    useEffect(() => {
        const getUserList = () => {
            setLoadercounter(true)
            fetch(`https://dashboard.aqaed.net/api/v1/getModuleShias/${getCountryActive.toLowerCase()}`)
                .then(res => res.json())
                .then(res => {
                    setcountry1(res.data.shias.data)
                });
            fetch(`https://dashboard.aqaed.net/api/v1/archiveInstitutes?country=${getCountryActive}`)
                .then(res => res.json())
                .then(res => {
                    setInstitutes(res.meta.total);
                    setLoadercounter(false)
                });
        }
        getUserList();
    }, [getCountryActive]);


    useEffect(() => {
        async function getUserList() {
            // Fetch data
            const {data} = await axios.get("https://dashboard.aqaed.net/api/v1/getCountryShia");

            const results = []
            data.data?.forEach((value) => {
                if (value?.length > 0)
                    results.push({
                        value: value,
                        label: countries.getName(value, 'ar'),
                    });
            });
            setCountryMap(results);
            setCountryMap2(results);
        }

        getUserList();
    }, []);


    const getLocalizedText = ({
                                  countryCode,
                                  countryValue,
                                  prefix,
                                  suffix,
                              }: CountryContext) => ``;

    let data = [];
    for (let i = 0; i < props.data.length; i++) {
        data.push({country: props.data[i], value: 5});
    }

    const getStyle = ({
                          countryValue,
                          countryCode,
                          minValue,
                          maxValue,
                          color,
                      }: CountryContext) => ({
        fill: countryValue && countryCode === getCountryActive ? '#990000' : countryValue && countryCode != getCountryActive ? color : '#E3E3E3',
        fillOpacity: 0.9,
        stroke: "transparent",
        strokeWidth: 1,
        strokeOpacity: 0,
        cursor: "pointer",
    });

    function openSelect() {
        setOpenSelect(!getOpenSelect);
    }

    function setIsShown(iso) {
        if (getOpenSelect)
            setCountryActive(iso.toLocaleUpperCase());
    }

    function setIsShownClick(iso) {
        setOpenSelect(false);
        setCountryActive(iso.toLocaleUpperCase());
    }


    const searchItems = (searchValue) => {
        if (searchValue.length > 0) {
            setCountryFilter(true)
            const newData = getCountryMap2.filter(item => {
                    if (item.label)
                        return item.label.includes(searchValue)
                }
            );
            setCountryMap(newData);
        } else {
            setCountryMap(getCountryMap2);
            setCountryFilter(false)
        }
    }

    const clickAction = React.useCallback(
        ({countryName, countryCode, countryValue}: CountryContext) => {
            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i].toLowerCase() == countryCode.toLowerCase()) {
                    setCountryActive(countryCode)
                }
            }
        }
    );

    return (
        <div className="country-module position-relative">
            <div className="aq-container pt-4 d-flex justify-content-between align-items-center">
                <div className="title-container mt-5 pe-5 pb-4">
                    <h2>
                        الشيعة والتشيّع
                    </h2>
                    <p>
                        عدد أتباع مذهب أهل البيت(عليهم السلام) في العالم، نشاطاتهم وأماكن تواجدهم، مساجدهم وحسينيّاتهم،
                        ومؤسّساتهم، مواقعهم وصفحاتهم
                    </p>
                </div>
            </div>
            <div className="aq-container ps-5 pt-4">
                <div className="row change-order-container align-items-center">
                    <div className="col-xxl-4 col-md-5">
                        <div className="container-country">
                            <div className="card-header-country d-flex justify-content-between">
                                <div className="c-name d-flex align-items-center" onClick={openSelect}>
                                    <img
                                        src={"https://dashboard.aqaed.net/flags/" + getCountryActive.toLowerCase() + ".svg"}
                                        style={{
                                            width: '1.4em',
                                            height: '1.1em',
                                        }}
                                        title={countries.getName(getCountryActive, "ar")}
                                        alt={countries.getName(getCountryActive, "ar")}
                                    />
                                    <span>{countries.getName(getCountryActive, "ar")}</span>
                                    <svg className="me-1 ms-2" width="12" height="10" viewBox="0 0 12 10" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1463_8171)">
                                            <path d="M2 3.5L6 7.5L10 3.5" stroke="white" strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1463_8171">
                                                <rect width="9" height="12" fill="white"
                                                      transform="translate(12 0.5) rotate(90)"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="d-flex">
                                    <div className="c-left">
                                        <div className="number">
                                            {
                                                getLoadercounter ?
                                                    <img className="image-loading" src={image} alt="loading"
                                                         title="loading"/>
                                                    : getInstitutes
                                            }
                                        </div>
                                        <div className="small-name">
                                            مؤسسات الشيعة
                                        </div>
                                    </div>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.2758 1.89941C16.7236 2.12335 20.5306 4.47471 23.1059 5.48244C24.7854 6.15425 26.465 6.60213 27.6966 7.49789C29.0403 8.39364 30.048 9.51334 30.4959 11.7527C30.6079 12.3126 30.7198 12.7605 30.7198 13.0964H1.71973C1.71973 12.6485 1.8317 12.2006 1.94367 11.7527C2.39154 9.62531 3.39927 8.39364 4.7429 7.49789C6.08654 6.60213 7.76608 6.15425 9.33365 5.48244C12.0209 4.47471 15.8279 2.12335 16.2758 1.89941Z"
                                            fill="white"/>
                                        <path
                                            d="M1.71973 14.104H30.7198C30.6079 16.1195 29.6001 17.1272 28.9283 18.5828H3.51124C2.83942 17.1272 1.8317 16.1195 1.71973 14.104Z"
                                            fill="white"/>
                                        <path
                                            d="M2.27994 19.7026H30.2723V29.7799H28.0329V26.8687C28.0329 26.4208 27.809 26.0849 27.4731 25.749C27.2491 25.5251 26.9132 25.3011 26.5773 25.1891C26.1294 24.9652 25.6816 24.8532 25.4576 24.8532C25.3457 24.8532 25.2337 24.7413 25.1217 24.7413C25.0097 24.7413 25.0097 24.7413 24.8978 24.7413C24.7858 24.7413 24.3379 24.8532 23.7781 25.0772C23.4422 25.1891 23.1063 25.4131 22.8823 25.637C22.6584 25.861 22.3225 26.1969 22.3225 26.7567V29.6679H20.0831V25.861C20.0831 25.3011 19.7472 24.8532 19.4113 24.5173C19.0754 24.1814 18.5155 23.9575 18.0676 23.7335C17.2839 23.3976 16.5001 23.1737 16.3881 23.1737H16.2761C16.1642 23.1737 16.1642 23.1737 16.0522 23.1737C15.9402 23.1737 15.1564 23.3976 14.3726 23.7335C13.9248 23.9575 13.4769 24.1814 13.029 24.5173C12.6931 24.8532 12.3572 25.3011 12.3572 25.861V29.7799H10.1178V26.8687C10.1178 26.4208 9.89386 26.0849 9.55796 25.749C9.33402 25.5251 8.99811 25.3011 8.6622 25.1891C8.21432 24.9652 7.76644 24.8532 7.5425 24.8532C7.43053 24.8532 7.31857 24.7413 7.2066 24.7413C7.09463 24.7413 7.09463 24.7413 6.98266 24.7413C6.87069 24.7413 6.42281 24.8532 5.86296 25.0772C5.52705 25.1891 5.19115 25.4131 4.96721 25.637C4.74327 25.861 4.40736 26.1969 4.40736 26.7567V29.6679H2.16797V19.7026H2.27994Z"
                                            fill="white"/>
                                        <path
                                            d="M16.2758 24.1816C16.3878 24.1816 17.0596 24.4056 17.7314 24.6295C18.0673 24.8535 18.5152 24.9654 18.7391 25.1894C18.9631 25.4133 19.075 25.6372 19.075 25.7492V29.6681H13.4766V25.8612C13.4766 25.7492 13.5885 25.5253 13.8125 25.3013C14.0364 25.0774 14.4843 24.8535 14.8202 24.7415C15.492 24.4056 16.1638 24.2936 16.2758 24.1816Z"
                                            fill="white"/>
                                        <path
                                            d="M7.31821 25.9731C7.43018 25.9731 7.76609 26.0851 8.21397 26.3091C8.43791 26.421 8.66185 26.533 8.88579 26.645C8.99776 26.7569 8.99776 26.8689 8.99776 26.8689V29.7801H5.63867V26.8689C5.63867 26.8689 5.63867 26.7569 5.75064 26.645C5.86261 26.533 6.08655 26.421 6.42246 26.3091C6.87034 26.0851 7.20624 25.9731 7.31821 25.9731Z"
                                            fill="white"/>
                                        <path
                                            d="M25.2333 25.9731C25.3452 25.9731 25.6811 26.0851 26.129 26.3091C26.3529 26.421 26.5769 26.533 26.8008 26.645C27.0248 26.7569 26.9128 26.8689 26.9128 26.8689V29.7801H23.5537V26.8689C23.5537 26.8689 23.5537 26.7569 23.6657 26.645C23.7777 26.533 24.0016 26.421 24.3375 26.3091C24.7854 26.0851 25.1213 25.9731 25.2333 25.9731Z"
                                            fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="c-body-gray">
                                <div className={getOpenSelect ? "c-list-container open " : "c-list-container"}>
                                    <div className="input-container position-sticky mt-3">
                                        <form className="botton-submit1">
                                            <div className="search-sub-menu w-100">
                                                <div
                                                    className="input-group search-input-group search-input-group-2 position-relative">
                                                <span className="input-group-btn input-group-btn-22 btn-search">
                                                    <button className="btn" type='submit' onClick={(event) => {
                                                        event.preventDefault()
                                                    }}>
                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M9.66667 15.8333C13.3486 15.8333 16.3333 12.8486 16.3333 9.16667C16.3333 5.48477 13.3486 2.5 9.66667 2.5C5.98477 2.5 3 5.48477 3 9.16667C3 12.8486 5.98477 15.8333 9.66667 15.8333Z"
                                                                stroke="#FFFFFFCC" strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                            <path d="M17.999 17.4999L14.374 13.8749" stroke="#FFFFFFCC"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                       </svg>
                                                    </button>
                                                </span>
                                                    <input
                                                        className="search-text-box-1 form-control form-control-2 form-control-45"
                                                        onChange={(e) => searchItems(e.target.value)}
                                                        placeholder="البحث ..."/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <ul className={getOpenSelect ? "c-list open " : "c-list"}>
                                        {
                                            getCountryMap.length > 0 ?
                                                getCountryMap.map(country =>
                                                    <li className="c-name"
                                                        onMouseEnter={() => setIsShown(country.value)}
                                                        onClick={() => setIsShownClick(country.value)}>
                                                        <img
                                                            src={"https://dashboard.aqaed.net/flags/" + country.value.toLowerCase() + ".svg"}
                                                            style={{
                                                                width: '1.4em',
                                                                height: '1.1em',
                                                            }}
                                                            title={country.label}
                                                            alt={country.label}
                                                        />
                                                        <span>{country.label}</span>
                                                    </li>
                                                )
                                                : ""
                                        }
                                    </ul>
                                </div>
                                <p className="d-block">
                                    {getcountry1.length > 0 ?

                                        getcountry1.map((shia, key) =>
                                            (getCountryActive.toLocaleUpperCase() == shia.country.toLocaleUpperCase()) && key == 0 ?
                                                shia.general_information.length > 300 ?
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: shia.general_information.substring(0, 300) + "..."}}/>
                                                    :
                                                    <div dangerouslySetInnerHTML={{__html: shia.general_information}}/>


                                                : ""
                                        )
                                        : ""}
                                    <div
                                        className="more-btn-container btn-bottom-shia d-flex justify-content-end mt-3 btn-red btn-limit">
                                        <NavLink className="more-btn"
                                                 to={"/shiites-dispersion?country=" + getCountryActive}>
                                            المزيد
                                        </NavLink>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 user-slider p-0 pe-5">
                        <WorldMap
                            color="#045E5E"
                            size="responsive"
                            data={data}
                            styleFunction={getStyle}
                            tooltipTextFunction={getLocalizedText}
                            onClickFunction={clickAction}
                        />
                    </div>
                </div>
            </div>
            <div className="aq-container p-1">
                <div className="slider-org-module mb-5">
                    <Marquee
                        direction={"right"}
                        speed={100}
                        loop={2000}
                        gradient={true}
                        pauseOnHover={true}
                        gradientWidth={20}
                    >
                        {props.data3.length > 0 ?
                            props.data3.map(InstitutesList =>
                                <NavLink className="mx-4 d-flex gap-2 align-items-center"
                                         to={"https://" + InstitutesList.link_site}
                                         target="_blank" rel="nofollow"
                                         title={InstitutesList.title}>
                                    <svg className="map-svg" width="56" height="56" viewBox="0 0 56 56" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="22.5896" cy="28.4924" r="22.2693" fill="#990000"/>
                                        <path
                                            d="M18.5957 26.8335C18.5957 27.7618 18.9645 28.652 19.6208 29.3084C20.2772 29.9647 21.1674 30.3335 22.0957 30.3335C23.024 30.3335 23.9142 29.9647 24.5706 29.3084C25.227 28.652 25.5957 27.7618 25.5957 26.8335C25.5957 25.9052 25.227 25.015 24.5706 24.3586C23.9142 23.7022 23.024 23.3335 22.0957 23.3335C21.1674 23.3335 20.2772 23.7022 19.6208 24.3586C18.9645 25.015 18.5957 25.9052 18.5957 26.8335Z"
                                            stroke="white" strokeOpacity="0.9" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M28.6968 33.4333L23.7467 38.3835C23.3092 38.8206 22.716 39.0661 22.0976 39.0661C21.4792 39.0661 20.886 38.8206 20.4485 38.3835L15.4972 33.4333C14.1919 32.128 13.3031 30.465 12.943 28.6545C12.5829 26.844 12.7677 24.9674 13.4742 23.262C14.1806 21.5566 15.3769 20.0989 16.9117 19.0734C18.4466 18.0479 20.2511 17.5005 22.097 17.5005C23.943 17.5005 25.7474 18.0479 27.2823 19.0734C28.8171 20.0989 30.0134 21.5566 30.7198 23.262C31.4263 24.9674 31.6111 26.844 31.251 28.6545C30.891 30.465 30.0021 32.128 28.6968 33.4333Z"
                                            stroke="white" strokeOpacity="0.9" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                    <h3 className="m-0">{InstitutesList.title}</h3>
                                    <span>/</span>
                                    <p className=" m-0 site-link">{InstitutesList.link_site}</p>

                                    {InstitutesList.twitter?.length > 0 ?
                                        <p className="m-0">
                                            {/*twitter*/}
                                            <span>{InstitutesList.twitter}</span>
                                            <svg width="29" height="28" viewBox="0 0 29 28" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1030_27586)">
                                                    <path
                                                        d="M14.2989 27.4123C21.7062 27.4123 27.7111 21.4074 27.7111 14.0001C27.7111 6.59273 21.7062 0.587891 14.2989 0.587891C6.89156 0.587891 0.886719 6.59273 0.886719 14.0001C0.886719 21.4074 6.89156 27.4123 14.2989 27.4123Z"
                                                        fill="#767676"/>
                                                    <path
                                                        d="M19.7384 12.3794C19.7475 12.51 19.7475 12.6406 19.7475 12.7724C19.7475 16.7883 16.6903 21.4198 11.1 21.4198V21.4174C9.44866 21.4198 7.83159 20.9468 6.44141 20.0549C6.68153 20.0838 6.92286 20.0982 7.16478 20.0988C8.53331 20.1 9.86271 19.6409 10.9394 18.7953C9.63883 18.7706 8.4984 17.9227 8.1 16.6848C8.55557 16.7726 9.02499 16.7546 9.47213 16.6324C8.05426 16.3459 7.03419 15.1002 7.03419 13.6534C7.03419 13.6402 7.03419 13.6276 7.03419 13.6149C7.45666 13.8502 7.92969 13.9808 8.41355 13.9953C7.07812 13.1028 6.66648 11.3262 7.47291 9.93724C9.01596 11.836 11.2926 12.9902 13.7366 13.1124C13.4916 12.0568 13.8262 10.9507 14.6158 10.2087C15.8399 9.05799 17.7651 9.11697 18.9158 10.3405C19.5964 10.2062 20.2488 9.9565 20.8458 9.60263C20.6189 10.3062 20.1441 10.9037 19.5098 11.2835C20.1122 11.2125 20.7007 11.0512 21.255 10.8051C20.847 11.4165 20.333 11.9491 19.7384 12.3794Z"
                                                        fill="white"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1030_27586">
                                                        <rect width="28.1656" height="26.8244" fill="white"
                                                              transform="translate(0.214844 0.587891)"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </p>
                                        : ""}
                                    {InstitutesList.instagram?.length > 0 ?
                                        <p className="m-0">
                                            {/*WHATSAPP*/}
                                            <span>{InstitutesList.instagram}</span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1428_7767)">
                                                    <path
                                                        d="M12.0568 23.2044C18.2447 23.2044 23.2611 18.1881 23.2611 12.0001C23.2611 5.81211 18.2447 0.795776 12.0568 0.795776C5.86887 0.795776 0.852539 5.81211 0.852539 12.0001C0.852539 18.1881 5.86887 23.2044 12.0568 23.2044Z"
                                                        fill="#767676"></path>
                                                    <path
                                                        d="M15.5404 7.76538C15.1147 7.76538 14.7695 8.1107 14.7695 8.53632C14.7695 8.96193 15.1147 9.30726 15.5404 9.30726C15.966 9.30726 16.3114 8.96193 16.3114 8.53632C16.3114 8.1107 15.966 7.76538 15.5404 7.76538Z"
                                                        fill="white"></path>
                                                    <path
                                                        d="M12.1137 8.75171C10.3293 8.75171 8.87891 10.2036 8.87891 11.9864C8.87891 13.7692 10.3309 15.2212 12.1137 15.2212C13.8964 15.2212 15.3484 13.7692 15.3484 11.9864C15.3484 10.2036 13.8964 8.75171 12.1137 8.75171ZM12.1137 14.06C10.9701 14.06 10.0417 13.13 10.0417 11.988C10.0417 10.8461 10.9717 9.91615 12.1137 9.91615C13.2556 9.91615 14.1856 10.8461 14.1856 11.988C14.1856 13.13 13.2556 14.06 12.1137 14.06Z"
                                                        fill="white"></path>
                                                    <path
                                                        d="M14.686 18.5524H9.44035C7.26406 18.5524 5.49414 16.7824 5.49414 14.6061V9.36055C5.49414 7.18425 7.26406 5.41431 9.44035 5.41431H14.686C16.8623 5.41431 18.6322 7.18425 18.6322 9.36055V14.6061C18.6322 16.7824 16.8623 18.5524 14.686 18.5524ZM9.44035 6.65101C7.94666 6.65101 6.73085 7.86685 6.73085 9.36055V14.6061C6.73085 16.0999 7.94666 17.3156 9.44035 17.3156H14.686C16.1797 17.3156 17.3955 16.0999 17.3955 14.6061V9.36055C17.3955 7.86525 16.1797 6.65101 14.686 6.65101H9.44035Z"
                                                        fill="white"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1428_7767">
                                                        <rect width="23.529" height="22.4086" fill="white"
                                                              transform="translate(0.295898 0.795715)"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </p>
                                        : ""}
                                    {InstitutesList.facebook?.length > 0 ?
                                        <p className="m-0">
                                            {/*FACEBOOK*/}
                                            <span>{InstitutesList.facebook}</span>
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M27.7413 15.0029C27.7413 8.10073 22.146 2.50537 15.2437 2.50537C8.34145 2.50537 2.74609 8.10073 2.74609 15.0029C2.74609 21.2408 7.31628 26.4111 13.2909 27.3487V18.6155H10.1177V15.0029H13.2909V12.2496C13.2909 9.11738 15.1567 7.38724 18.0114 7.38724C19.3787 7.38724 20.809 7.63134 20.809 7.63134V10.7069H19.233C17.6806 10.7069 17.1964 11.6703 17.1964 12.6586V15.0029H20.6625L20.1085 18.6155H17.1964V27.3487C23.171 26.4111 27.7413 21.2408 27.7413 15.0029Z"
                                                    fill="#767676"/>
                                            </svg>
                                        </p>
                                        : ""}
                                </NavLink>
                            )
                            : ""}
                    </Marquee>

                </div>
            </div>

            <div className="aq-container left-line-3"></div>
            <div className="aq-container left-line-4"></div>
        </div>
    );
};

export default Country;
